import { BannerType, HEADER_HEIGHT, useToast } from '@pelando/components';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { getConfirmEmailUrl } from '../../services/urls';
import { ConfirmationEmailStatus } from '../../types/schema';
import { useIdentifyConfirmEmailRoutes } from './hook';
import { sendEmailConfirmation } from '../../../infra/api/auth/endpoints';
import { useTranslation } from '../useTranslation';

export function useEnhancedIdentifyConfirmEmailRoutes() {
  const { t } = useTranslation('common');
  const { query, asPath, push } = useRouter();
  const { showToast } = useToast();
  const status = query.status as ConfirmationEmailStatus;

  const isConfirmEmailPage = asPath === getConfirmEmailUrl(status);

  const handleResend = useCallback(() => {
    sendEmailConfirmation
      .requestAsPromise()
      .then(({ data }) => {
        if (!data) throw new Error();
        showToast({
          titleText: t('use-enhanced-identify-confirm-email-routes-title'),
          text: t('use-enhanced-identify-confirm-email-routes-text'),
          bannerType: BannerType.SUCCESS,
          topPosition: HEADER_HEIGHT,
        });
      })
      .catch(() => {
        showToast({
          titleText: 'Oops!',
          text: t('use-enhanced-identify-confirm-email-routes-error-text'),
          bannerType: BannerType.ERROR,
          topPosition: HEADER_HEIGHT,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToast]);

  useEffect(() => {
    if (!isConfirmEmailPage) return;
    push('/');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmEmailPage]);

  useIdentifyConfirmEmailRoutes({
    status,
    onResendToken: handleResend,
  });
}
