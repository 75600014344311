import { MONTHS } from './consts';
import { getMonthSuffix } from './getMonthSuffix';

export const getLongMonth = (date: Date, language: string = 'pt-BR') =>
  MONTHS[language]?.LONG_MONTHS[date.getMonth()];

export const getDayAndLongMonth = (dateString: string, language: string) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const longMonth = MONTHS[language].LONG_MONTHS[date.getMonth()];

  if (language.startsWith('en')) {
    const suffix = getMonthSuffix(day);
    return `${longMonth} ${day}${suffix}`;
  }
  return `${day} de ${longMonth}`;
};
