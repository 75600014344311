import { combineLatestWith, filter, map, tap } from 'rxjs';
import { toPromise } from '../../core/helpers/toPromise';
import { userOfferVotes } from '../../votes/endpoints';
import { GetCommentedOffersVariables } from '../types/CommentedOffers';
import { dealsAreSaved } from '../../savedDeals/endpoints';
import { getCommentedOffers } from '../endpoints';

export const getCommentedOffersHelper = (
  variables: GetCommentedOffersVariables
) =>
  toPromise(
    getCommentedOffers.request(variables).pipe(
      filter(({ data }) => !!data),
      tap((message) => {
        const offerIds = message.data?.edges.map((deal) => deal.id) || [];
        userOfferVotes.requestAsPromise({ offerIds });
      })
    )
  );

export const getCommentedOffers$ = getCommentedOffers.cache$.pipe(
  combineLatestWith(userOfferVotes.cache$, dealsAreSaved.cache$),
  map(([dealsCache, votes, savedDeals]) => {
    const nextTemperature = (dealId: string, current?: number) =>
      typeof votes?.[dealId]?.temperature === 'number'
        ? votes?.[dealId]?.temperature
        : current;

    const mappedDeals = dealsCache?.commentedOffers?.edges.map((deal) => ({
      ...deal,
      temperature: nextTemperature(deal.id, deal.temperature) || 0,
      voteInfo: votes?.[deal.id],
      isSaved: savedDeals?.some((savedDeal) => savedDeal?.offer.id === deal.id),
    }));

    return {
      ...dealsCache,
      commentedOffers: { ...dealsCache?.commentedOffers, edges: mappedDeals },
    };
  })
);
