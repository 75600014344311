import { getCommunityOffers$ } from '@/infra/api/offers/helpers/getCommunityOffers';
import { getOffersByCategory$ } from '../../../../../../infra/api/offers/helpers/getOffersByCategory';
import { useApiCache } from '../../../../../hooks/useApiCache';

type UseCategoryFeedCacheProps = {
  communitiesEnabled: boolean;
};

export const useCategoryFeedCache = ({
  communitiesEnabled,
}: UseCategoryFeedCacheProps) => {
  const { categoryOffers, loading, isPaginationLoading } =
    useApiCache(getOffersByCategory$) || {};

  const {
    communityOffers,
    loading: loadingCommunity,
    isPaginationLoading: isPaginationLoadingCommunity,
  } = useApiCache(getCommunityOffers$) || {};

  return {
    edges: communitiesEnabled ? communityOffers?.edges : categoryOffers?.edges,
    loading: communitiesEnabled ? loadingCommunity : loading,
    pageInfo: communitiesEnabled
      ? communityOffers?.pageInfo
      : categoryOffers?.pageInfo,
    paginationLoading: communitiesEnabled
      ? isPaginationLoadingCommunity
      : isPaginationLoading,
    offsetBasedPageInfo: communitiesEnabled
      ? communityOffers?.offsetBasedPageInfo
      : undefined,
  };
};
