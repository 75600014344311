import { combineLatestWith, filter, map, tap } from 'rxjs';
import { toPromise } from '../../core/helpers/toPromise';
import { userOfferVotes } from '../../votes/endpoints';
import { dealsAreSaved } from '../../savedDeals/endpoints';
import { getOffersByCategory } from '../endpoints';
import { GetExploreFeedParams } from './getExploreFeed';

export const getOffersByCategoryHelper = (variables: GetExploreFeedParams) =>
  toPromise(
    getOffersByCategory.request(variables).pipe(
      filter(({ data }) => !!data),
      tap((message) => {
        const offerIds = message.data?.edges.map((deal) => deal.id) || [];
        userOfferVotes.requestAsPromise({ offerIds });
      })
    )
  );

export const getOffersByCategoryServerSide = (
  variables: GetExploreFeedParams
) =>
  toPromise(
    getOffersByCategory
      .request(variables)
      .pipe(filter(({ data }) => !!data))
  );

export const getOffersByCategory$ = getOffersByCategory.cache$.pipe(
  combineLatestWith(userOfferVotes.cache$, dealsAreSaved.cache$),
  map(([dealsCache, votes, savedDeals]) => {
    const nextTemperature = (dealId: string, current?: number) =>
      typeof votes?.[dealId]?.temperature === 'number'
        ? votes?.[dealId]?.temperature
        : current;

    const mappedDeals = dealsCache?.categoryOffers?.edges.map((deal) => ({
      ...deal,
      temperature: nextTemperature(deal.id, deal.temperature) || 0,
      voteInfo: votes?.[deal.id],
      isSaved: savedDeals?.some((savedDeal) => savedDeal?.offer.id === deal.id),
    }));

    return {
      ...dealsCache,
      categoryOffers: { ...dealsCache?.categoryOffers, edges: mappedDeals },
    };
  })
);
