import { DEAL_FEED_FRAGMENT } from '../../offers/queries/shared';

export const UPDATE_SAVED_DEAL_MUTATION = `
  mutation UpdateSavedDealMutation(
    $offerId: ID!
    $notificationsEnabled: Boolean!
  ) {
    me {
      updateFollowedOffer(
        offerId: $offerId
        input: { notificationsEnabled: $notificationsEnabled }
      ) {
        id
        notificationsEnabled
        offer {
          ...DealFeedFragment
        }
      }
    }
  }

  ${DEAL_FEED_FRAGMENT}
`;
