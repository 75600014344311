import { OfferStatus } from '../../../../domain/entities/Offer';
import {
  DEFAULT_FEED_QUERIES_LIMIT,
  DefaultFeedVariables,
} from '../types/shared';
import { GetOffersByCategoryVariables } from '../types/OffersByCategory';
import { FilterOptions } from '../../../../domain/entities/Filter';
import { getOffersByCategoryHelper } from './getOffersByCategory';

export type GetExploreFeedParams = {
  categorySlug: string;
  limit?: number;
  after?: DefaultFeedVariables['after'];
  filter?: Partial<Omit<FilterOptions, 'hottestFeedPeriod'>>;
};

export default function getExploreFeed({
  categorySlug,
  after,
  filter,
}: GetExploreFeedParams) {
  const variables: GetOffersByCategoryVariables = {
    categorySlug,
    after,
    limit: DEFAULT_FEED_QUERIES_LIMIT,
    sortBy: filter?.sortBy,
  };

  if (filter?.hideExpired) {
    variables.filters = { status: OfferStatus.ACTIVE };
  }

  return getOffersByCategoryHelper(variables);
}
