import { FEED_FRAGMENT } from './shared';

const RECENTS_OFFERS_QUERY = `
  query RecentOffersQuery(
    $limit: Int
    $after: String
    $filters: OfferFilterOptions
    $page: Int
  ) {
    public {
      recentOffers(limit: $limit, after: $after, filters: $filters, page: $page) {
        ...FeedFragment
      }
    }
  }

  ${FEED_FRAGMENT}
`;

export default RECENTS_OFFERS_QUERY;
