import { OfferStatus } from '../../../domain/entities/Offer';
import { createNewEndpoint } from '../core/endpoint';
import { GraphQlMethods } from '../core/fetch';
import { PreventUnauthenticatedGuard } from '../core/guards/preventUnauthenticated';
import { FilterActionType } from './actionTypes';
import { UPDATE_USER_FILTER_PREFERENCES_MUTATION } from './mutations/updateUserFilterPreference';
import { GET_USER_FILTER_PREFERENCES_QUERY } from './queries/getUserFilterPreference';
import {
  GetUserFilterPreferenceResponse,
  FilterCache,
  UpdateUserFilterPreferenceResponse,
  UserFilterPreferenceVariables,
  FilterPreference,
} from './types/UserFilterPreference';

export const getUserFilterPreference = createNewEndpoint<
  GetUserFilterPreferenceResponse,
  void,
  FilterPreference,
  FilterCache
>({
  id: FilterActionType.GetUserFilterPreference,
  query: GET_USER_FILTER_PREFERENCES_QUERY,
  method: GraphQlMethods.Get,
  guards: [PreventUnauthenticatedGuard],
  transform: (response) => response.me.userFilterPreferences,
  cacheTransform: ({ data, loading }, current) => {
    const { hottestFeedPeriod, sortBy } = data?.preferences || {};
    const hideExpired = data?.preferences?.status === OfferStatus.ACTIVE;

    const newData = {
      hideExpired,
      hottestFeedPeriod,
      sortBy,
      loading,
    };

    return { ...current, ...newData, loading };
  },
});

export const updateUserFilterPreference = createNewEndpoint<
  UpdateUserFilterPreferenceResponse,
  UserFilterPreferenceVariables,
  FilterPreference,
  undefined
>({
  id: FilterActionType.UpdateUserFilterPreference,
  query: UPDATE_USER_FILTER_PREFERENCES_MUTATION,
  method: GraphQlMethods.Post,
  guards: [PreventUnauthenticatedGuard],
  transform: (response) => response.me.updateUserFilterPreference,
  cacheTransform: ({ data, loading }) => {
    if (!data) return undefined;

    const { hottestFeedPeriod, sortBy } = data?.preferences || {};
    const hideExpired = data?.preferences?.status === OfferStatus.ACTIVE;

    const newData = {
      hideExpired,
      hottestFeedPeriod,
      sortBy,
      loading,
    };

    getUserFilterPreference.cache$.next({
      ...getUserFilterPreference.cache$.value,
      ...newData,
    });

    return undefined;
  },
});
