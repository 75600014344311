import { RECOMMENDED_FEED_FRAGMENT } from './shared';

export const getOfferRecomendationQueryText = (privateQuery?: boolean) => `
  query OffersRecommendationQuery($limit: Int, $after: String) {
    ${privateQuery ? 'me' : 'public'} {
      offerRecommendation(limit: $limit, after: $after) {
        ...RecommendedFeedFragment
        recommendationId
      }
    }
  }

  ${RECOMMENDED_FEED_FRAGMENT}
`;
