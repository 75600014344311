import { combineLatestWith, map } from 'rxjs';
import { userOfferVotes } from '../../votes/endpoints';
import { dealsAreSaved } from '../../savedDeals/endpoints';
import {
  getRecommendedPrivateOffers,
  getRecommendedPublicOffers,
} from '../endpoints';
import { OfferRecommendationCache } from '../types/RecommendedOffers';
import { UserOfferVotesCache } from '../../votes/types/UserOfferVotes';
import { SavedDealsListCache } from '../../savedDeals/types/SavedDeals';

const mapRecomendationDealsToDealsWithVote = (
  dealsCache?: OfferRecommendationCache,
  votes?: UserOfferVotesCache,
  savedDeals?: SavedDealsListCache
) => {
  const nextTemperature = (dealId: string, current?: number) =>
    typeof votes?.[dealId]?.temperature === 'number'
      ? votes?.[dealId]?.temperature
      : current;

  const mappedDeals = dealsCache?.offerRecommendation?.edges.map((deal) => ({
    ...deal,
    temperature: nextTemperature(deal.id, deal.temperature) || 0,
    voteInfo: votes?.[deal.id],
    isSaved: savedDeals?.some((savedDeal) => savedDeal?.offer.id === deal.id),
    recommendationId: dealsCache?.offerRecommendation?.recommendationId,
  }));

  return {
    ...dealsCache,
    offerRecommendation: {
      ...dealsCache?.offerRecommendation,
      edges: mappedDeals,
    },
  };
};

export const getRecommendedPrivateOffers$ =
  getRecommendedPrivateOffers.cache$.pipe(
    combineLatestWith(userOfferVotes.cache$, dealsAreSaved.cache$),
    map(([dealsCache, votes, savedDeals]) =>
      mapRecomendationDealsToDealsWithVote(dealsCache, votes, savedDeals)
    )
  );

export const getRecommendedPublicOffers$ =
  getRecommendedPublicOffers.cache$.pipe(
    combineLatestWith(userOfferVotes.cache$, dealsAreSaved.cache$),
    map(([dealsCache, votes, savedDeals]) =>
      mapRecomendationDealsToDealsWithVote(dealsCache, votes, savedDeals)
    )
  );
