import { FILTER_PREFERENCE_FRAGMENT } from '../fragments';

export const GET_USER_FILTER_PREFERENCES_QUERY = `
  query GetUserFilterPreferences {
    me {
      userFilterPreferences {
        ...FilterPreferenceFragment
      }
    }
  }

  ${FILTER_PREFERENCE_FRAGMENT}
`;
