import { EventCategory, GeneralDealEvents } from '@/domain/events/analytics';
import { emitTrackingEvent } from '@/infra/analytics/emitTrackingEvent';
import {
  SavedDealInfo,
  SavedDealsInfo,
} from '../../../domain/entities/SavedDeal';
import { createNewEndpoint } from '../core/endpoint';
import { AuthGuard } from '../core/guards/authGuard';
import { PreventUnauthenticatedGuard } from '../core/guards/preventUnauthenticated';
import { GraphQlMethods } from '../core/fetch';
import { SavedDealsActionType } from './actionTypes';
import { REMOVE_SAVED_DEAL_MUTATION } from './mutations/removeSavedDeal';
import { SAVE_DEAL_MUTATION } from './mutations/saveDeal';
import { UPDATE_SAVED_DEAL_MUTATION } from './mutations/updateSavedDeal';
import { DEAL_IS_SAVED_QUERY } from './queries/dealIsSaved';
import { DealIsSavedResponse, DealIsSavedVariables } from './types/DealIsSaved';
import {
  RemoveSavedDealResponse,
  RemoveSavedDealVariables,
} from './types/RemoveSavedDeal';
import { SaveDealResponse, SaveDealVariables } from './types/SaveDeal';
import {
  SavedDealsCache,
  SavedDealsFeedCache,
  SavedDealsListCache,
  SavedDealsResponse,
  SavedDealsVariables,
} from './types/SavedDeals';
import {
  UpdateSavedDealResponse,
  UpdateSavedDealVariables,
} from './types/UpdateSavedDeal';
import { DEAL_ARE_SAVED_QUERY } from './queries/dealsAreSaved';
import {
  DealsAreSavedResponse,
  DealsAreSavedVariables,
} from './types/DealsAreSaved';
import { SAVED_DEALS_QUERY } from './queries/savedDeals';

export const dealIsSaved = createNewEndpoint<
  DealIsSavedResponse,
  DealIsSavedVariables,
  SavedDealInfo,
  SavedDealsCache
>({
  id: SavedDealsActionType.DealIsSaved,
  query: DEAL_IS_SAVED_QUERY,
  method: GraphQlMethods.Get,
  guards: [PreventUnauthenticatedGuard],
  transform: (response) => response.me.offerIsFollowed ?? undefined,
  cacheTransform: (incoming, current) => {
    if (!incoming.data?.offer) return undefined;

    const nextData = { [incoming.data.offer.id]: incoming.data };
    return { ...current, ...nextData };
  },
});

export const dealsAreSaved = createNewEndpoint<
  DealsAreSavedResponse,
  DealsAreSavedVariables,
  SavedDealsInfo[] | undefined,
  SavedDealsListCache
>({
  id: SavedDealsActionType.DealsAreSaved,
  query: DEAL_ARE_SAVED_QUERY,
  method: GraphQlMethods.Get,
  guards: [PreventUnauthenticatedGuard],
  transform: (response) => response.me.offersAreFollowed,
  cacheTransform: ({ data }, current) => [...(current || []), ...(data || [])],
});

export const saveDeal = createNewEndpoint<
  SaveDealResponse,
  SaveDealVariables,
  SavedDealInfo
>({
  id: SavedDealsActionType.SaveDeal,
  query: SAVE_DEAL_MUTATION,
  method: GraphQlMethods.Post,
  guards: [AuthGuard],
  transform: (response) => {
    const result = response.me.followOffer;

    dealIsSaved.cache$.next({ [result.offer.id]: result });

    const dealsSavedCache = dealsAreSaved.cache$.value;

    if (dealsSavedCache) {
      dealsSavedCache.push({
        id: result.id,
        notificationsEnabled: result.notificationsEnabled,
        offer: { id: result.offer.id },
      });
    }

    dealsAreSaved.cache$.next(dealsSavedCache);
    // TODO: Corrigir a forma com o retry é feito pra usar esse evento após o sucesso do retry
    emitTrackingEvent({
      category: EventCategory.GeneralDeal,
      name: GeneralDealEvents.DealSave,
      extra: undefined,
    });

    return result;
  },
});

export const getSavedDeals = createNewEndpoint<
  SavedDealsResponse,
  SavedDealsVariables,
  SavedDealsResponse['me']['followedOffers'],
  SavedDealsFeedCache
>({
  id: SavedDealsActionType.GetSavedDeals,
  query: SAVED_DEALS_QUERY,
  guards: [PreventUnauthenticatedGuard],
  method: GraphQlMethods.Get,
  transform: (response) => response.me.followedOffers,
  cacheTransform: ({ variables, loading, data }, current) => {
    const currentEdges = current?.edges || [];
    const newEdges = data?.edges || [];

    const edges = variables?.after ? [...currentEdges, ...newEdges] : newEdges;

    return {
      edges,
      loading,
      paginationLoading: !!variables?.after && loading,
      pageInfo: data?.pageInfo,
    };
  },
});

export const removeSavedDeal = createNewEndpoint<
  RemoveSavedDealResponse,
  RemoveSavedDealVariables,
  boolean
>({
  id: SavedDealsActionType.RemoveSavedDeal,
  query: REMOVE_SAVED_DEAL_MUTATION,
  method: GraphQlMethods.Post,
  guards: [AuthGuard],
  transform: (response, variables) => {
    dealIsSaved.cache$.next({ [variables.offerId]: undefined });

    const dealsSavedCache = dealsAreSaved.cache$.value;
    if (dealsSavedCache) {
      const filteredDeals = dealsSavedCache.filter(
        (savedDeal) => savedDeal?.offer.id !== variables.offerId
      );
      dealsAreSaved.cache$.next(filteredDeals);
    }

    const savedDealsFeedCache = getSavedDeals.cache$.value;
    if (savedDealsFeedCache) {
      const filteredDeals = savedDealsFeedCache.edges.filter(
        (savedDeal) => savedDeal?.offer.id !== variables.offerId
      );
      getSavedDeals.cache$.next({
        ...savedDealsFeedCache,
        edges: filteredDeals,
      });
    }

    return response.me.unfollowOffer;
  },
});

export const updateSavedDeal = createNewEndpoint<
  UpdateSavedDealResponse,
  UpdateSavedDealVariables,
  SavedDealInfo
>({
  id: SavedDealsActionType.UpdateSavedDeal,
  query: UPDATE_SAVED_DEAL_MUTATION,
  method: GraphQlMethods.Post,
  guards: [AuthGuard],
  transform: (response, variables) => {
    const result = response.me.updateFollowedOffer;

    dealIsSaved.cache$.next({ [variables.offerId]: result });

    const savedDealsFeedCache = getSavedDeals.cache$.value;
    if (savedDealsFeedCache) {
      const mappedDeals = savedDealsFeedCache.edges.map((savedDeal) =>
        savedDeal.offer.id === variables.offerId ? result : savedDeal
      );

      getSavedDeals.cache$.next({
        ...savedDealsFeedCache,
        edges: mappedDeals,
      });
    }

    return result;
  },
});
