import {
  getRecommendedPrivateOffers,
  getRecommendedPublicOffers,
} from '@/infra/api/offers/endpoints';
import { OfferRecommendation } from '@/infra/api/offers/types/RecommendedOffers';
import { getCommentedOffers$ } from '../../../../../../infra/api/offers/helpers/getCommentedFeed';
import { getHottestOffers$ } from '../../../../../../infra/api/offers/helpers/getHottestFeed';
import { getRecentsOffers$ } from '../../../../../../infra/api/offers/helpers/getRecentsFeed';
import {
  getRecommendedPrivateOffers$,
  getRecommendedPublicOffers$,
} from '../../../../../../infra/api/offers/helpers/getRecommendationFeed';
import { useApiCache } from '../../../../../hooks/useApiCache';
import {
  shouldActivateFeedRecombee,
  shouldActivateFeedRecombeeLoggedOut,
} from '../../../../../services/featureFlags';
import { SpecialFeedTab } from '../../../../../services/feedTabsCategories';

type UseFeedCacheProps = {
  tab: SpecialFeedTab;
  isLogged: boolean;
  recombeeQueryFailed: boolean;
};

export const useFeedCache = ({
  tab,
  isLogged,
  recombeeQueryFailed,
}: UseFeedCacheProps) => {
  const {
    commentedOffers,
    loading: commentedLoading,
    isPaginationLoading: commentedPaginationLoading,
  } = useApiCache(getCommentedOffers$) || {};

  const {
    recentOffers,
    loading: recentLoading,
    isPaginationLoading: recentPaginationLoading,
  } = useApiCache(getRecentsOffers$) || {};

  const {
    hottestOffers,
    loading: hottestLoading,
    isPaginationLoading: hottestPaginationLoading,
  } = useApiCache(getHottestOffers$) || {};

  const {
    offerRecommendation: offerRecommendationPrivate,
    loading: recommendationPrivateLoading,
    isPaginationLoading: recommendationPrivatePaginationLoading,
  } = useApiCache(getRecommendedPrivateOffers$) || {};

  const {
    offerRecommendation: offerRecommendationPublic,
    loading: recommendationPublicLoading,
    isPaginationLoading: recommendationPublicPaginationLoading,
  } = useApiCache(getRecommendedPublicOffers$) || {};

  const getFeaturedCache = () => {
    if (!recombeeQueryFailed) {
      if (shouldActivateFeedRecombee() && isLogged) {
        if (!getRecommendedPublicOffers.cache$.value) {
          return {
            feedOffersCache: offerRecommendationPrivate,
            loading: recommendationPrivateLoading,
            isPaginationLoading: recommendationPrivatePaginationLoading,
          };
        }

        getRecommendedPrivateOffers.cache$.next({
          offerRecommendation: {
            ...(getRecommendedPublicOffers.cache$.value
              ?.offerRecommendation as OfferRecommendation),
          },
          isPaginationLoading:
            getRecommendedPublicOffers.cache$.value?.isPaginationLoading,
        });
        getRecommendedPublicOffers.cache$.next(undefined);

        // eslint-disable-next-line consistent-return
        return;
      }
      if (shouldActivateFeedRecombeeLoggedOut())
        return {
          feedOffersCache: offerRecommendationPublic,
          loading: recommendationPublicLoading,
          isPaginationLoading: recommendationPublicPaginationLoading,
        };
    }

    return {
      feedOffersCache: offerRecommendationPublic,
      loading: recommendationPublicLoading,
      isPaginationLoading: recommendationPublicPaginationLoading,
      totalPages: undefined,
    };
  };

  const cache = {
    [SpecialFeedTab.COMMENTED]: {
      feedOffersCache: commentedOffers,
      loading: commentedLoading,
      isPaginationLoading: commentedPaginationLoading,
      totalPages: commentedOffers?.offsetBasedPageInfo?.totalPages,
    },
    [SpecialFeedTab.HOTTEST]: {
      feedOffersCache: hottestOffers,
      loading: hottestLoading,
      isPaginationLoading: hottestPaginationLoading,
      totalPages: hottestOffers?.offsetBasedPageInfo?.totalPages,
    },
    [SpecialFeedTab.RECENT]: {
      feedOffersCache: recentOffers,
      loading: recentLoading,
      isPaginationLoading: recentPaginationLoading,
      totalPages: recentOffers?.offsetBasedPageInfo?.total,
    },
    [SpecialFeedTab.FEATURED]: getFeaturedCache(),
  }[tab];

  return cache;
};
