import { FEED_FRAGMENT } from './shared';

const OFFERS_BY_CATEGORY_QUERY = `
  query OffersByCategory(
    $categorySlug: String!
    $limit: Int
    $after: String
    $filters: OfferFilterOptions
    $sortBy: OfferSortOption
  ) {
    public {
      offers(
        categorySlug: $categorySlug
        limit: $limit
        after: $after
        filters: $filters
        sortBy: $sortBy
      ) {
        ...FeedFragment
      }
    }
  }

  ${FEED_FRAGMENT}
`;

export default OFFERS_BY_CATEGORY_QUERY;
