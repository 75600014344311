import { EndpointGuardMessage } from '.';
import { getProbablyLoggedCookie } from '../../../auth/tokenManagement';

/**
 * A Guard to prevent a public request to be called by an logged user.
 *
 * @param message Guard message from endpoint (EndpointGuardMessage)

 * @returns If the request can continue
 */
export function preventPublicQueryWhenLogged<Response, Variables>(
  message: EndpointGuardMessage<Response, Variables>
) {
  if (message.loading && getProbablyLoggedCookie()) {
    return true;
  }

  return false;
}
