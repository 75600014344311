const prefix = '@offers';

export const OfferActionType = {
  GetFeaturedOffers: `${prefix}/GET_FEATURED_OFFERS`,
  GetRecentsOffers: `${prefix}/GET_RECENTS_OFFERS`,
  GetCommentedOffers: `${prefix}/GET_COMMENTED_OFFERS`,
  GetHottestOffers: `${prefix}/GET_HOTTEST_OFFERS`,
  GetOffersByCategoryOffers: `${prefix}/GET_OFFERS_BY_CATEGORY_OFFERS`,
  GetRecommendedPrivateOffers: `${prefix}/OFFERS_RECOMMENDATION_PRIVATE_QUERY`,
  GetRecommendedPublicOffers: `${prefix}/OFFERS_RECOMMENDATION_PUBLIC_QUERY`,
  GetCommunityOffers: `${prefix}/GET_COMMUNITY_OFFERS`,
};
