import { FeaturedOffer } from '../../../../domain/entities/Offer';
import {
  shouldActivateFeedRecombee,
  shouldActivateFeedRecombeeLoggedOut,
} from '../../../../presentation/services/featureFlags';
import { getProbablyLoggedCookie } from '../../../auth/tokenManagement';
import * as offerEndpoints from '../endpoints';
import { GetRecommendedOffersVariables } from '../types/RecommendedOffers';

enum RECOMENDATIONENDPOINT {
  PRIVATE = 'getRecommendedPrivateOffers',
  PUBLIC = 'getRecommendedPublicOffers',
}

const fallbackToFeaturedOffers = async ({
  endpoint,
}: {
  endpoint: RECOMENDATIONENDPOINT;
}) => {
  const { data } = await offerEndpoints.getFeaturedOffers.requestAsPromise({
    limit: 15,
    after:
      offerEndpoints.getFeaturedOffers.cache$.value?.featuredOffers?.pageInfo
        ?.endCursor,
  });

  offerEndpoints[endpoint].cache$.next({
    loading: false,
    offerRecommendation: {
      edges: [
        ...(offerEndpoints[endpoint].cache$.value?.offerRecommendation?.edges ||
          []),
        ...(data?.edges || []),
      ] as unknown as FeaturedOffer[],
      pageInfo:
        offerEndpoints[endpoint].cache$.value?.offerRecommendation?.pageInfo ||
        {},
      recommendationId:
        offerEndpoints[endpoint].cache$.value?.offerRecommendation
          ?.recommendationId || '',
    },
  });
};

export const getRecommendationEndpointRequest = ({
  variables,
}: {
  variables: GetRecommendedOffersVariables;
  onError?: () => void;
}) => {
  if (shouldActivateFeedRecombee() && getProbablyLoggedCookie()) {
    return offerEndpoints.getRecommendedPrivateOffers
      .requestAsPromise(variables)
      .then(({ data }) => {
        if (data?.edges.length === 0) {
          fallbackToFeaturedOffers({ endpoint: RECOMENDATIONENDPOINT.PRIVATE });
        }
      })
      .catch(async (error: { error: unknown }) => {
        if (error.error) {
          fallbackToFeaturedOffers({ endpoint: RECOMENDATIONENDPOINT.PRIVATE });
        }
      });
  }
  if (shouldActivateFeedRecombeeLoggedOut()) {
    return offerEndpoints.getRecommendedPublicOffers
      .requestAsPromise(variables)
      .then(({ data }) => {
        if (data?.edges.length === 0) {
          fallbackToFeaturedOffers({ endpoint: RECOMENDATIONENDPOINT.PUBLIC });
        }
      })
      .catch(async (error: { error: unknown }) => {
        if (error.error) {
          fallbackToFeaturedOffers({ endpoint: RECOMENDATIONENDPOINT.PUBLIC });
        }
      });
  }

  return null;
};
