import { createNewEndpoint } from '../core/endpoint';
import { GraphQlMethods } from '../core/fetch';
import { preventPublicQueryWhenLogged } from '../core/guards/preventPublicQueryWhenLogged';
import { ResponseResult } from '../core/request';
import { OfferActionType } from './actionTypes';
import {
  FEATURED_OFFERS_QUERY,
  RECENTS_OFFERS_QUERY,
  COMMENTED_OFFERS_QUERY,
  HOTTEST_OFFERS_FEED_QUERY,
  OFFERS_BY_CATEGORY_QUERY,
} from './queries';
import COMMUNITY_OFFERS_QUERY from './queries/getCommunityOffersQuery';
import { getOfferRecomendationQueryText } from './queries/getOffersRecomendationQuery';

import {
  CommentedOffers,
  CommentedOffersCache,
  GetCommentedOffersResponse,
  GetCommentedOffersVariables,
} from './types/CommentedOffers';
import {
  CommunitysOffers,
  CommunitysOffersCache,
  GetCommunitysOffersResponse,
  GetCommunitysOffersVariables,
} from './types/CommunityOffers';
import {
  FeaturedOffers,
  FeaturedOffersCache,
  GetFeaturedOffersResponse,
  GetFeaturedOffersVariables,
} from './types/FeaturedOffers';
import {
  GetHottestFeedOffersResponse,
  GetHottestOffersVariables,
  HottestFeedOffers,
  HottestOffersCache,
} from './types/HottestOffers';
import {
  GetOffersByCategoryResponse,
  GetOffersByCategoryVariables,
  OffersByCategory,
  OffersByCategoryCache,
} from './types/OffersByCategory';
import {
  GetRecentsOffersResponse,
  GetRecentsOffersVariables,
  RecentsOffers,
  RecentsOffersCache,
} from './types/RecentOffers';
import {
  GetRecommendedOffersPrivateResponse,
  GetRecommendedOffersPublicResponse,
  GetRecommendedOffersVariables,
  OfferRecommendation,
  OfferRecommendationCache,
} from './types/RecommendedOffers';
import { DefaultFeedVariables } from './types/shared';

export const getFeaturedOffers = createNewEndpoint<
  GetFeaturedOffersResponse,
  GetFeaturedOffersVariables,
  FeaturedOffers,
  FeaturedOffersCache
>({
  id: OfferActionType.GetFeaturedOffers,
  query: FEATURED_OFFERS_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.featuredOffers,
  cacheTransform: (response, current) => {
    const { data, loading, variables } = response;
    const isPagination = !!variables?.after;

    const currentEdges = current?.featuredOffers?.edges || [];
    const responseEdges = data?.edges || [];

    const edges = isPagination
      ? [...currentEdges, ...responseEdges]
      : responseEdges;

    const isPaginationLoading = isPagination && loading;
    const isLoading = loading && !isPagination;

    const featuredOffers = {
      ...data,
      edges,
    };

    return {
      featuredOffers,
      loading: isLoading,
      isPaginationLoading,
    };
  },
});

export const getRecentsOffers = createNewEndpoint<
  GetRecentsOffersResponse,
  GetRecentsOffersVariables,
  RecentsOffers,
  RecentsOffersCache
>({
  id: OfferActionType.GetRecentsOffers,
  query: RECENTS_OFFERS_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.recentOffers,
  cacheTransform: (response, current) => {
    const { data, loading, variables } = response;
    const isPagination = !!variables?.after;

    const currentEdges = current?.recentOffers?.edges || [];
    const responseEdges = data?.edges || [];

    const edges = isPagination
      ? [...currentEdges, ...responseEdges]
      : responseEdges;

    const isPaginationLoading = isPagination && loading;
    const isLoading = loading && !isPagination;

    const responseOffsetBasedPageInfo = data?.offsetBasedPageInfo;
    const currentOffsetBasedPageInfo =
      current?.recentOffers.offsetBasedPageInfo;

    const offsetBasedPageInfoChanged =
      responseOffsetBasedPageInfo &&
      responseOffsetBasedPageInfo?.totalPages !==
        currentOffsetBasedPageInfo?.totalPages;

    const offsetBasedPageInfo = offsetBasedPageInfoChanged
      ? responseOffsetBasedPageInfo
      : currentOffsetBasedPageInfo;

    const recentOffers = {
      ...data,
      edges,
      offsetBasedPageInfo,
    };

    return {
      recentOffers,
      loading: isLoading,
      isPaginationLoading,
    };
  },
});

export const getHottestFeedOffers = createNewEndpoint<
  GetHottestFeedOffersResponse,
  GetHottestOffersVariables,
  HottestFeedOffers,
  HottestOffersCache
>({
  id: OfferActionType.GetHottestOffers,
  query: HOTTEST_OFFERS_FEED_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.hottestOffersFeed,
  cacheTransform: (response, current) => {
    const { data, loading, variables } = response;
    const isPagination = !!variables?.after;

    const currentEdges = current?.hottestOffers?.edges || [];
    const responseEdges = data?.edges || [];

    const edges = isPagination
      ? [...currentEdges, ...responseEdges]
      : responseEdges;

    const isPaginationLoading = isPagination && loading;
    const isLoading = loading && !isPagination;

    const responseOffsetBasedPageInfo = data?.offsetBasedPageInfo;
    const currentOffsetBasedPageInfo =
      current?.hottestOffers.offsetBasedPageInfo;

    const offsetBasedPageInfoChanged =
      responseOffsetBasedPageInfo &&
      responseOffsetBasedPageInfo?.totalPages !==
        currentOffsetBasedPageInfo?.totalPages;

    const offsetBasedPageInfo = offsetBasedPageInfoChanged
      ? responseOffsetBasedPageInfo
      : currentOffsetBasedPageInfo;

    const hottestOffers = {
      ...data,
      edges,
      offsetBasedPageInfo,
    };

    return {
      hottestOffers,
      loading: isLoading,
      isPaginationLoading,
      variables,
    };
  },
});

export const getCommentedOffers = createNewEndpoint<
  GetCommentedOffersResponse,
  GetCommentedOffersVariables,
  CommentedOffers,
  CommentedOffersCache
>({
  id: OfferActionType.GetCommentedOffers,
  query: COMMENTED_OFFERS_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.commentedOffers,
  cacheTransform: (response, current) => {
    const { data, loading, variables } = response;
    const isPagination = !!variables?.after;

    const currentEdges = current?.commentedOffers?.edges || [];

    const responseEdges = data?.edges || [];

    const edges = isPagination
      ? [...currentEdges, ...responseEdges]
      : responseEdges;

    const isPaginationLoading = isPagination && loading;
    const isLoading = loading && !isPagination;

    const responseOffsetBasedPageInfo = data?.offsetBasedPageInfo;
    const currentOffsetBasedPageInfo =
      current?.commentedOffers.offsetBasedPageInfo;

    const offsetBasedPageInfoChanged =
      responseOffsetBasedPageInfo &&
      responseOffsetBasedPageInfo?.totalPages !==
        currentOffsetBasedPageInfo?.totalPages;

    const offsetBasedPageInfo = offsetBasedPageInfoChanged
      ? responseOffsetBasedPageInfo
      : currentOffsetBasedPageInfo;

    const commentedOffers = {
      ...data,
      offsetBasedPageInfo,
      edges,
    };

    return {
      commentedOffers,
      loading: isLoading,
      isPaginationLoading,
    };
  },
});

const getRecommendedOffersCacheTransform = (
  response: ResponseResult<OfferRecommendation, DefaultFeedVariables>,
  current?: OfferRecommendationCache
) => {
  const { data, loading, error, variables } = response;
  const recommendationId = data?.recommendationId || '';

  const isPagination = !!variables?.after;

  const isPaginationLoading = isPagination && loading;
  const isLoading = loading && !isPagination;

  const currentOfferRecommendation = current?.offerRecommendation;

  if (isPaginationLoading || error)
    return {
      offerRecommendation: currentOfferRecommendation,
      loading,
      isPaginationLoading,
    };

  const currentEdges = currentOfferRecommendation?.edges || [];
  const responseEdges = data?.edges || [];

  const edges = isPagination
    ? [...currentEdges, ...responseEdges]
    : responseEdges;

  const hasData = data !== null;

  const pageInfo = hasData
    ? data?.pageInfo
    : currentOfferRecommendation?.pageInfo;

  const offerRecommendation = {
    edges,
    recommendationId,
    pageInfo: pageInfo || {},
  };

  return {
    offerRecommendation,
    loading: isLoading,
    isPaginationLoading,
  };
};

export const getRecommendedPublicOffers = createNewEndpoint<
  GetRecommendedOffersPublicResponse,
  GetRecommendedOffersVariables,
  OfferRecommendation,
  OfferRecommendationCache
>({
  id: OfferActionType.GetRecommendedPublicOffers,
  query: getOfferRecomendationQueryText(),
  method: GraphQlMethods.Get,
  guards: [preventPublicQueryWhenLogged],
  transform: (response) => response.public.offerRecommendation,
  cacheTransform: getRecommendedOffersCacheTransform,
});

export const getRecommendedPrivateOffers = createNewEndpoint<
  GetRecommendedOffersPrivateResponse,
  GetRecommendedOffersVariables,
  OfferRecommendation,
  OfferRecommendationCache
>({
  id: OfferActionType.GetRecommendedPrivateOffers,
  query: getOfferRecomendationQueryText(true),
  method: GraphQlMethods.Get,
  transform: (response) => response.me.offerRecommendation,
  cacheTransform: getRecommendedOffersCacheTransform,
});

export const getOffersByCategory = createNewEndpoint<
  GetOffersByCategoryResponse,
  GetOffersByCategoryVariables,
  OffersByCategory,
  OffersByCategoryCache
>({
  id: OfferActionType.GetOffersByCategoryOffers,
  query: OFFERS_BY_CATEGORY_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.offers,
  cacheTransform: (response, current) => {
    const { data, loading, variables } = response;

    const isPagination = !!variables?.after;

    const currentEdges = current?.categoryOffers?.edges || [];
    const responseEdges = data?.edges || [];

    const edges = isPagination
      ? [...currentEdges, ...responseEdges]
      : responseEdges;

    const isPaginationLoading = isPagination && loading;
    const isLoading = loading && !isPagination;

    const categoryOffers = {
      ...data,
      edges,
    };

    return {
      categoryOffers,
      loading: isLoading,
      isPaginationLoading,
    };
  },
});

export const getCommunityOffers = createNewEndpoint<
  GetCommunitysOffersResponse,
  GetCommunitysOffersVariables,
  CommunitysOffers,
  CommunitysOffersCache
>({
  id: OfferActionType.GetCommunityOffers,
  query: COMMUNITY_OFFERS_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.communityOffers,
  cacheTransform: (response, current) => {
    const { data, loading, variables } = response;
    const isPagination = !!variables?.after;

    const currentEdges = current?.communityOffers?.edges || [];
    const responseEdges = data?.edges || [];

    const edges = isPagination
      ? [...currentEdges, ...responseEdges]
      : responseEdges;

    const isPaginationLoading = isPagination && loading;
    const isLoading = loading && !isPagination;

    const responseOffsetBasedPageInfo = data?.offsetBasedPageInfo;
    const currentOffsetBasedPageInfo =
      current?.communityOffers.offsetBasedPageInfo;

    const offsetBasedPageInfoChanged =
      responseOffsetBasedPageInfo &&
      responseOffsetBasedPageInfo?.totalPages !==
        currentOffsetBasedPageInfo?.totalPages;

    const offsetBasedPageInfo = offsetBasedPageInfoChanged
      ? responseOffsetBasedPageInfo
      : currentOffsetBasedPageInfo;

    const communityOffers = {
      ...data,
      edges,
      offsetBasedPageInfo,
    };

    return {
      communityOffers,
      loading: isLoading,
      isPaginationLoading,
    };
  },
});
