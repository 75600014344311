import { DEAL_FEED_FRAGMENT } from '../../offers/queries/shared';

export const DEAL_IS_SAVED_QUERY = `
  query DealIsFollowedQuery($offerId: ID!) {
    me {
      offerIsFollowed(offerId: $offerId) {
        id
        notificationsEnabled
        offer {
          ...DealFeedFragment
        }
      }
    }
  }

  ${DEAL_FEED_FRAGMENT}
`;
