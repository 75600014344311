import { getOnboardingCommunitiesLocalStorage } from '@/infra/storage/onboardingManagement';
import * as offersNewEndpoints from '../endpoints';
import { GetFeaturedOffersVariables } from '../types/FeaturedOffers';
import { GetHottestOffersVariables } from '../types/HottestOffers';
import { GetRecentsOffersVariables } from '../types/RecentOffers';
import { GetCommentedOffersVariables } from '../types/CommentedOffers';
import { FilterOptions } from '../../../../domain/entities/Filter';
import {
  DEFAULT_FEED_QUERIES_LIMIT,
  DefaultFeedVariables,
} from '../types/shared';
import { OfferStatus } from '../../../../domain/entities/Offer';
import getMinDateByHottestFilterOption from './getMinDateByHottestFilterOption';
import { getRecommendationEndpointRequest } from './getRecommendationEndpointRequest';

export enum FeedQueries {
  COMMENTED = 'getCommentedOffers',
  FEATURED = 'getFeaturedOffers',
  HOTTEST = 'getHottestFeedOffers',
  RECENT = 'getRecentsOffers',
  RECOMBEE = 'getRecommendedPrivateOffers',
  RECOMBEE_PUBLIC = 'getRecommendedPublicOffers',
}

type Variables = GetCommentedOffersVariables &
  GetFeaturedOffersVariables &
  GetHottestOffersVariables &
  GetRecentsOffersVariables;

export type FeedVariabledByFeedQuery = {
  [FeedQueries.COMMENTED]: GetCommentedOffersVariables;
  [FeedQueries.FEATURED]: GetFeaturedOffersVariables;
  [FeedQueries.HOTTEST]: GetHottestOffersVariables;
  [FeedQueries.RECENT]: GetRecentsOffersVariables;
};

export type GetSpecialFeedParams = {
  feed: FeedQueries;
  after?: DefaultFeedVariables['after'];
  page?: DefaultFeedVariables['page'];
  filter?: Partial<Omit<FilterOptions, 'sortBy'>>;
  recombeeQueryFailed?: boolean;
};

export function getSpecialFeed({
  feed,
  after,
  filter,
  recombeeQueryFailed,
  page,
}: GetSpecialFeedParams) {
  const variables: Variables = {
    limit: DEFAULT_FEED_QUERIES_LIMIT,
  };

  if (after) {
    variables.after = after;
  } else if (page && feed !== FeedQueries.FEATURED) {
    variables.page = page;
  }

  if (feed === FeedQueries.FEATURED && !recombeeQueryFailed) {
    return getRecommendationEndpointRequest({
      variables,
    });
  }

  if (feed !== FeedQueries.FEATURED && filter?.hideExpired) {
    variables.filters = { status: OfferStatus.ACTIVE };
  }

  if (feed === FeedQueries.HOTTEST && filter?.hottestFeedPeriod) {
    const communityIds = getOnboardingCommunitiesLocalStorage();
    variables.minDate = getMinDateByHottestFilterOption(
      filter.hottestFeedPeriod
    );
    if (communityIds?.length) {
      variables.filters = { status: OfferStatus.ACTIVE, communityIds };
    }
  }

  if (feed) {
    return offersNewEndpoints[feed].requestAsPromise(variables);
  }

  return offersNewEndpoints[FeedQueries.FEATURED].requestAsPromise(variables);
}
