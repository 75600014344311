import {
  DEAL_FEED_FRAGMENT,
  OFFSET_BASED_PAGE_INFO_FRAGMENT,
  PAGE_INFO_FRAGMENT,
} from './shared';

const COMMUNITY_OFFERS_QUERY = `
query CommunityOffersQuery(
    $communitySlug: String!
    $limit: Int
    $after: String
    $page: Int
    $filters: OfferFilterOptions
    $sortBy: OfferSortOption
    $minDate: DateTime
  ) {
    public {
      communityOffers(communitySlug: $communitySlug, limit: $limit, page: $page, after: $after, filters: $filters, sortBy: $sortBy, minDate: $minDate) {
        edges {
          ...DealFeedFragment
        }
        pageInfo {
          ...PageInfoFragment
        }
        offsetBasedPageInfo {
          ...OffsetBasedPaginationInfoFragment
        }
      }
    }
  }

  ${DEAL_FEED_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
  ${OFFSET_BASED_PAGE_INFO_FRAGMENT}
`;

export default COMMUNITY_OFFERS_QUERY;
