import { FILTER_PREFERENCE_FRAGMENT } from '../fragments';

export const UPDATE_USER_FILTER_PREFERENCES_MUTATION = `
  mutation UpdateUserFilterPreferencesMutation(
    $input: UserFilterPreferenceInput!
  ) {
    me {
      updateUserFilterPreference(input: $input) {
        ...FilterPreferenceFragment
      }
    }
  }

  ${FILTER_PREFERENCE_FRAGMENT}
`;
