import { OfferSortOption } from '../../../domain/entities/Filter';
import { SpecialFeedTab } from '../feedTabsCategories';

export const getFeedTabNameByTab = (tab: SpecialFeedTab) =>
  ({
    [SpecialFeedTab.HOTTEST]: 'mais_quentes',
    [SpecialFeedTab.RECENT]: 'recentes',
    [SpecialFeedTab.COMMENTED]: 'comentadas',
    [SpecialFeedTab.FEATURED]: 'destaques',
  }[tab]);

export default function getTabNameBySortOption(sortBy?: OfferSortOption) {
  if (!sortBy) return '';
  return {
    [OfferSortOption.COMMENT]: 'recem_comentadas',
    [OfferSortOption.CREATED_AT]: 'mais_recentes',
    [OfferSortOption.TEMPERATURE]: 'mais_quentes',
    [OfferSortOption.RELEVANCE]: 'mais_relevantes',
  }[sortBy];
}
