import { useRouter } from 'next/router';
import SEO from '@/presentation/components/SEO';
import { ValidFeedSlug } from '@/presentation/services/urls/feed';
import { parseFeedQueryParams } from '@/presentation/services/feed';
import { Langs } from '@/locales';
import {
  getFeedCanonical,
  getFeedDescription,
  getHomeFeedRss,
  getNewFeedTitle,
} from './helpers';

export type FeedSEOProps = {
  categorySlug: string;
};

export default function FeedSEO({ categorySlug }: FeedSEOProps) {
  const { locale, query } = useRouter();

  const { page } = parseFeedQueryParams(query);

  const description = getFeedDescription({
    slug: categorySlug,
    locale,
  });

  const canonical = getFeedCanonical({
    page,
    slug: categorySlug,
    locale: locale as Langs,
  });

  const rss = getHomeFeedRss(categorySlug as ValidFeedSlug, locale as Langs);

  const title = getNewFeedTitle({
    slug: categorySlug,
    locale,
    page,
  });

  return (
    <SEO
      title={title}
      description={description}
      canonical={canonical}
      robots="index, follow"
      rss={rss}
    />
  );
}
