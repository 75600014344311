import { ReactElement } from 'react';
import { ConfirmButton, Container, Content, Title } from './styles';

export type ModalContentProps = {
  title: string;
  content: string;
  buttonText?: string;
  onConfirm?: () => void;
  icon: ReactElement;
};

export const ModalContent = ({
  buttonText,
  content,
  onConfirm,
  title,
  icon,
}: ModalContentProps) => (
  <Container>
    {icon}
    <Title>{title}</Title>
    <Content>{content}</Content>
    {buttonText ? (
      <ConfirmButton onClick={onConfirm}>{buttonText}</ConfirmButton>
    ) : undefined}
  </Container>
);
