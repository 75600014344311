import {
  BodyLight,
  Button,
  Colors,
  H2Heavy,
  H3Heavy,
  Icon,
  MediaQuery,
} from '@pelando/components';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  text-align: center;
`;

export const CheckIcon = styled(Icon)`
  font-size: 47px;
  margin-bottom: 16px;
  color: rgb(${Colors.Alien});
`;

export const ErrorIcon = styled(Icon)`
  font-size: 47px;
  margin-bottom: 16px;
  color: rgb(${Colors.Red});
`;

export const Title = styled.h1`
  ${H3Heavy}
  margin-bottom: 8px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H2Heavy}
  }
`;

export const Content = styled.p`
  ${BodyLight}
  color: rgb(${Colors.Gray});
  margin-bottom: 24px;
`;

export const ConfirmButton = styled(Button)`
  height: 48px;
  width: 100%;
`;

export const ModalStyle = css`
  @media ${MediaQuery.SCREEN_MD_UP} {
    min-width: 391px;
  }
`;
