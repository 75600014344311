export const DEAL_ARE_SAVED_QUERY = `
  query DealsAreFollowedQuery($offerIds: [ID!]!){
    me {
      offersAreFollowed(offerIds: $offerIds) {
        id
        notificationsEnabled
        offer {
          id
        }
      }
    }
  }

`;
