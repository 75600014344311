import { DEAL_FEED_FRAGMENT } from '../../offers/queries/shared';

export const SAVED_DEALS_QUERY = `
  query SavedDealsQuery($limit: Int, $after: String) {
    me {
      followedOffers(limit: $limit, after: $after) {
        edges {
          id
          notificationsEnabled
          offer {
            ...DealFeedFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }

  ${DEAL_FEED_FRAGMENT}
`;
