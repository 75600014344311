import { DatePeriod } from '../../../../domain/entities/Filter';

const datePeriodToDays: Record<DatePeriod, number> = {
  [DatePeriod.TODAY]: 0,
  [DatePeriod.WEEK]: 7,
  [DatePeriod.MONTH]: 30,
};

export default function getMinDateByHottestFilterOption(option: DatePeriod) {
  const today = new Date();
  const finalDate = new Date();

  finalDate.setDate(today.getDate() - datePeriodToDays[option]);
  finalDate.setMinutes(0);
  finalDate.setHours(0);

  if (option === DatePeriod.TODAY) {
    finalDate.setHours(today.getHours() - 24);
  }

  return finalDate.toISOString();
}
