import { DEAL_FEED_FRAGMENT } from '../../offers/queries/shared';

export const SAVE_DEAL_MUTATION = `
  mutation SaveDealMutation($offerId: ID!, $options: OfferOptions) {
    me {
      followOffer(offerId: $offerId, options: $options) {
        id
        notificationsEnabled
        offer {
          ...DealFeedFragment
        }
      }
    }
  }

  ${DEAL_FEED_FRAGMENT}
`;
