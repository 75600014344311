export enum OfferReportReason {
  DUPLICATED = 'DUPLICATED',
  EXPIRED = 'EXPIRED',
  FOLLOW = 'FOLLOW',
  NOT_PROMOTION = 'NOT_PROMOTION',
  SPAM_OR_OFFENSIVE = 'SPAM_OR_OFFENSIVE',
}

export enum ConfirmationEmailStatus {
  SUCCESS = 'sucesso',
  EXPIRED_TOKEN = 'token-expirado',
  ERROR = 'erro',
}
