const prefix = '@saved-deals';

export const SavedDealsActionType = {
  DealsAreSaved: `${prefix}/DEAL_ARE_SAVED`,
  DealIsSaved: `${prefix}/DEAL_IS_SAVED`,
  SaveDeal: `${prefix}/SAVE_DEAL`,
  RemoveSavedDeal: `${prefix}/REMOVE_SAVED_DEAL`,
  UpdateSavedDeal: `${prefix}/UPDATE_SAVED_DEAL`,
  GetSavedDeals: `${prefix}/GET_SAVED_DEALS`,
};
