import { OfferStatus } from '../../../../domain/entities/Offer';
import {
  DEFAULT_FEED_QUERIES_LIMIT,
  DefaultFeedVariables,
} from '../types/shared';
import { FilterOptions } from '../../../../domain/entities/Filter';
import { GetCommunitysOffersVariables } from '../types/CommunityOffers';
import { getCommunityOffersHelper } from './getCommunityOffers';
import getMinDateByHottestFilterOption from './getMinDateByHottestFilterOption';

export type GetCommunityFeedParams = {
  communitySlug: string;
  limit?: number;
  page?: DefaultFeedVariables['page'];
  after?: DefaultFeedVariables['after'];
  filter?: FilterOptions;
};

export default function getCommunityFeed({
  communitySlug,
  page,
  filter,
  after,
}: GetCommunityFeedParams) {
  const variables: GetCommunitysOffersVariables = {
    communitySlug,
    limit: DEFAULT_FEED_QUERIES_LIMIT,
    sortBy: filter?.sortBy,
    filters: filter?.hideExpired ? { status: OfferStatus.ACTIVE } : undefined,
    ...(filter?.hottestFeedPeriod && {
      minDate: getMinDateByHottestFilterOption(filter.hottestFeedPeriod),
    }),
    ...(after && { after }),
    ...(page && !after && { page }),
  };

  return getCommunityOffersHelper(variables);
}
