import {
  DEAL_FEED_FRAGMENT,
  PAGE_INFO_FRAGMENT,
  COMMENT_FEED_FRAGMENT,
  OFFSET_BASED_PAGE_INFO_FRAGMENT,
} from './shared';

const COMMENTED_OFFERS_QUERY = `
  query CommentedOffersQuery(
    $limit: Int
    $after: String
    $filters: OfferFilterOptions
    $page: Int
  ) {
    public {
      commentedOffers(limit: $limit, after: $after, filters: $filters, page: $page) {
        edges {
          ...DealFeedFragment

          featuredComment {
            ...CommentFeedFragment
          }

          lastComment {
            ...CommentFeedFragment
          }
        }

        pageInfo {
          ...PageInfoFragment
        }

        offsetBasedPageInfo {
          ...OffsetBasedPaginationInfoFragment
        }
      }
    }
  }

  ${DEAL_FEED_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
  ${COMMENT_FEED_FRAGMENT}
  ${OFFSET_BASED_PAGE_INFO_FRAGMENT}
`;

export default COMMENTED_OFFERS_QUERY;
