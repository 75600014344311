import { FEED_FRAGMENT } from './shared';

const FEATURED_OFFERS_QUERY = `
  query FeaturedOffersQuery($limit: Int, $after: String) {
    public {
      featuredOffers(limit: $limit, after: $after) {
        ...FeedFragment
      }
    }
  }

  ${FEED_FRAGMENT}
`;

export default FEATURED_OFFERS_QUERY;
