import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { DatePeriod } from '@/domain/entities/Filter';
import { getUserFilterPreference } from '@/infra/api/filters/endpoints';
import { getSpecialFeed } from '@/infra/api/offers/helpers/getSpecialFeed';
import { FeedQueries } from '@/infra/api/offers/types/shared';
import { useApiCache } from '@/presentation/hooks/useApiCache';
import {
  SpecialFeedTab,
  getFeedTabFromPath,
} from '@/presentation/services/feedTabsCategories';
import { Langs } from '@/locales';

export const useRefetchHottestFeed = ({
  initialPage,
  isInitialPageLoaded,
}: {
  initialPage?: number;
  isInitialPageLoaded?: boolean;
}) => {
  const isFirstLoad = useRef(true);
  const { asPath, locale } = useRouter();
  const tab = getFeedTabFromPath(asPath, locale as Langs);

  const isHottestTab = useRef(tab === SpecialFeedTab.HOTTEST);

  const {
    hideExpired,
    hottestFeedPeriod = DatePeriod.TODAY,
    loading: isFiltersLoading = true,
  } = useApiCache(getUserFilterPreference.cache$) || {};

  useEffect(() => {
    if (
      !isHottestTab.current ||
      isFiltersLoading ||
      !isFirstLoad.current ||
      !isInitialPageLoaded
    )
      return;

    isFirstLoad.current = false;

    const filter = { hideExpired, hottestFeedPeriod };

    const isHottestFeedPeriodDifferentFromDefault =
      hottestFeedPeriod !== DatePeriod.TODAY;

    const shouldRefetch =
      isHottestFeedPeriodDifferentFromDefault || hideExpired;

    if (shouldRefetch) {
      getSpecialFeed({
        feed: FeedQueries.HOTTEST,
        filter,
        page: initialPage,
      });
    }
  }, [
    hottestFeedPeriod,
    hideExpired,
    isFiltersLoading,
    initialPage,
    isInitialPageLoaded,
  ]);
};
