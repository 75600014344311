import { FEED_FRAGMENT } from './shared';

const HOTTEST_OFFERS_FEED_QUERY = `
  query HottestOffersFeedQuery(
    $limit: Int
    $after: String
    $minDate: DateTime
    $filters: OfferFilterOptions
    $page: Int
  ) {
    public {
      hottestOffersFeed(
        limit: $limit
        after: $after
        minDate: $minDate
        filters: $filters
        page: $page
      ) {
        ...FeedFragment
      }
    }
  }

  ${FEED_FRAGMENT}
`;

export default HOTTEST_OFFERS_FEED_QUERY;
