import { useEffect } from 'react';
import { useModal } from '@pelando/components';
import { Check, TriangleExclamation } from '@pelando/fontawesome/icons';
import { CheckIcon, ErrorIcon, ModalStyle } from './styles';
import { ConfirmationEmailStatus } from '../../types/schema';
import { ModalContent, ModalContentProps } from './component';
import { useTranslation } from '../useTranslation';

export type UseIdentifyConfirmEmailRoutesParams = {
  status?: ConfirmationEmailStatus;
  onResendToken?: () => void;
  onFinish?: () => void;
};

export function useIdentifyConfirmEmailRoutes({
  status,
  onResendToken,
}: UseIdentifyConfirmEmailRoutesParams) {
  const { showModal, closeModal } = useModal();
  const { t } = useTranslation('common');

  const isConfirmationStatus = Object.values(ConfirmationEmailStatus).includes(
    status as ConfirmationEmailStatus
  );

  const getModalContentByStatus = (): ModalContentProps =>
    ({
      [ConfirmationEmailStatus.SUCCESS]: {
        icon: <CheckIcon icon={Check} />,
        title: t('email-confirm-title-success'),
        content: t('email-confirm-content-success'),
        buttonText: t('email-confirm-content-button-text-success'),
        onConfirm: closeModal,
      },
      [ConfirmationEmailStatus.EXPIRED_TOKEN]: {
        icon: <ErrorIcon icon={TriangleExclamation} />,
        title: t('email-confirm-title-expired'),
        content: t('email-confirm-content-expired'),
        buttonText: t('email-confirm-content-button-text-expired'),
        onConfirm: () => {
          onResendToken?.();
          closeModal();
        },
      },
      [ConfirmationEmailStatus.ERROR]: {
        icon: <ErrorIcon icon={TriangleExclamation} />,
        title: t('email-confirm-title-error'),
        content: t('email-confirm-content-error'),
      },
    }[status as ConfirmationEmailStatus]);

  useEffect(() => {
    if (!isConfirmationStatus) return;
    const content = getModalContentByStatus();
    showModal(<ModalContent {...content} />, {
      style: ModalStyle,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, onResendToken]);
}
