import { useEffect, useState } from 'react';

const useScrollToPagePosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    // if (typeof window !== undefined) {
    const hasScrollHistory = sessionStorage.getItem(
      // eslint-disable-next-line  @typescript-eslint/restrict-template-expressions
      `__next_scroll_${window.history.state.key}`
    );
    if (hasScrollHistory) {
      const obj = JSON.parse(hasScrollHistory);
      setScrollPosition(obj.y);
    }
    // }
  }, []);

  return { scrollPosition };
};

export default useScrollToPagePosition;
