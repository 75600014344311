import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useAuthModal } from '../../components/Authentication/hooks/useAuthModal';
import {
  AuthKind,
  LoginComponentSourceName,
} from '../../components/Authentication/types';
import { getLoginUrl, getSignupUrl } from '../../services/urls';

const AuthModal = dynamic(
  () =>
    import('@/presentation/components/Authentication').then(
      (mod) => mod.default
    ),
  { ssr: false }
);

type UseIdentifyAuthRoutesParams = {
  loading?: boolean;
  isLogged?: boolean;
  pathname: string;
};

const getAuthKindByPath = (
  pathname: string,
  locale?: string
): AuthKind | undefined =>
  ({
    [getLoginUrl(locale)]: AuthKind.LOGIN,
    [getSignupUrl(locale)]: AuthKind.SIGNUP,
  }[pathname]);

export function useIdentifyAuthRoutes({
  loading,
  isLogged,
  pathname,
}: UseIdentifyAuthRoutesParams) {
  const { showModal, closeModal } = useAuthModal();
  const { locale } = useRouter();
  const kind = getAuthKindByPath(pathname, locale);

  useEffect(() => {
    if (!kind || loading) return;

    if (isLogged) {
      closeModal();
      return;
    }

    if (!isLogged) {
      showModal(
        <AuthModal
          kind={kind}
          componentSourceName={LoginComponentSourceName.authRoute}
          onCloseModal={closeModal}
        />
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kind, isLogged, loading]);
}
